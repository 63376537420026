
.notfound {
  height: 100%
}
.notfound .container-footer{position: absolute;bottom: 0;}
/*.notfound .fixed-top{position: static;}*/
.notfound .block-center{ 
    width: 100%;
    color: #333333;
    height: 650px;
    margin: 0 auto;
    overflow: hidden;
    padding: 10px 0;
    align-items: center;
    justify-content: space-around;
    display: flex;
    float: none;
    text-align: center;
}
.notfound h1{font-size: 60px}

.notfound .box-table{ 
  width: 100%;
  color: #333333;
  height: 650px;
  margin: 0 auto;
  overflow: hidden;
  padding: 10px 0;
  align-items: center;
  justify-content: space-around;
  display: flex;
  float: none;
  text-align: center;
}
