@-moz-document url-prefix() {
    table.table-custom th, table.table-custom td {
        border-top: none !important;
    }

    .fixcol-fx {
        border-bottom: solid 1px #968c8b !important;
    }
}

a, a:hover {
    text-decoration: none !important;
}

.wrapper {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 75vh;
}

.wrapper-box {
    padding: 0px 25px 25px 25px;
    width: 100%;
}

.nav-header {
    height: 58px;
    border-bottom: solid 1px #ddd;
}

.row-cs {
    margin-right: 0px !important;
}

.blog-header {
    border-bottom: solid 1px #d1d2d7;
}

.header-logo img {
    max-width: 20%;
}

.header-logo-sm img {
    max-height: 57px;
}

.header-sub-logo img {
    max-width: 40%;
    margin-left: -32px;
}

.header-right-logo {
    padding-right: 15px;
}

.header-right-logo img {
    max-height: 38px;
}

.logo-right {
    margin-right: 10px;
}

.logo-right img {
    max-height: 38px;
}

.header-text {
    font-weight: bold;
    color: #3f619e;
    padding: 20px;
}

.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
    font-family: 'Lato', sans-serif !important;
}

.txt-14 {
    font-size: 14px;
    font-weight: bold;
}

.txt-20 {
    font-size: 20px;
}

/*info*/

.header-tabs-menu {
    width: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: dashed 1px #db1175;
}

.box-dropdown {
    display: inline-block; 
}

.button-menu {
    display: inline-block;
    border: solid 1px #e40b7e;
    padding: 5px 25px;
    color: #e40b7e;
    text-align: center;
    cursor: pointer;
    word-break: break-word;
    text-transform: uppercase;
    border-radius: 15px;
    background-color: #fff;
    margin-right: 20px;
    font-size: 15px;
}

.button-menu i {
    font-size: 18px;
    margin-right: 5px;
}

a.active .button-menu, .button-menu:hover, .button-circle:hover {
    background: rgba(249, 17, 117, 1);
    background: -moz-linear-gradient(top, rgba(249, 17, 117, 1) 0%, rgba(217, 17, 116, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(249, 17, 117, 1)), color-stop(100%, rgba(217, 17, 116, 1)));
    background: -webkit-linear-gradient(top, rgba(249, 17, 117, 1) 0%, rgba(217, 17, 116, 1) 100%);
    background: -o-linear-gradient(top, rgba(249, 17, 117, 1) 0%, rgba(217, 17, 116, 1) 100%);
    background: -ms-linear-gradient(top, rgba(249, 17, 117, 1) 0%, rgba(217, 17, 116, 1) 100%);
    background: linear-gradient(to bottom, rgba(249, 17, 117, 1) 0%, rgba(217, 17, 116, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f91175', endColorstr='#d91174', GradientType=0);
    color: #fff;
    cursor: pointer;
}

.button-circle {
    display: inline-block;
    border: solid 1px #e40b7e;
    border-radius: 50%;
    padding: 5px 8px;
    width: 28px;
    height: 28px;
    text-align: center;
    vertical-align: middle;
    line-height: 15px;
    background: none;
    color: #e40b7e;
    font-size: 14px;
}

.button-sm {
    padding: 3px 25px;
    font-size: 12px;
    width: 40% !important;
}

.box-round {
    display: inline-block;
    width: 28px;
    height: 28px;
    background-color: #e40b7e;
    color: #e2d9d9;
    border-radius: 50%;
    line-height: 27px;
    text-align: center;
}

.box-menu {
    display: block;
    position: absolute;
    right: 0;
    padding: 20px 30px;
}

.box-round-blue {
    display: inline-block;
    width: 23px;
    height: 23px;
    color: #fff;
    background: rgba(13, 75, 112, 1);
    background: -moz-linear-gradient(top, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(13, 75, 112, 1)), color-stop(100%, rgba(22, 21, 65, 1)));
    background: -webkit-linear-gradient(top, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
    background: -o-linear-gradient(top, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
    background: -ms-linear-gradient(top, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
    background: linear-gradient(to bottom, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0d4b70', endColorstr='#161541', GradientType=0);
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
    /* line-height: 9px; */
    float: left;
    padding: 1px;
    margin-right: 8px;
    cursor: pointer;
}

.btn-cs {
    background: none !important;
    padding: 0px !important;
}

.btn-cs:focus {
    box-shadow: none !important;
}

.wrapper-table {
    background-color: #fff;
    padding: 25px 25px 25px 35px;
    width: 100%;
    margin-top: 190px;
}

table.table-custom {
    width: 100%;
    text-align: center;
    border-top: solid 1px #968c8b;
}

table.table-custom th {
    font-size: 12px;
    font-weight: bold;
    color: #867772;
}

table.table-custom th, table.table-custom td {
    padding: 10px;
    vertical-align: middle;
    border-collapse: collapse;
    border-right: solid 1px #968c8b;
    border-top: solid 1px #968c8b;
    border-bottom: solid 1px #968c8b;
    border-spacing: 0;
}

table.table-custom td:first-child {
    border-right: 0px;
}

.box-flag {
    display: inline-block;
    float: left;
    margin-right: 8px;
    margin-top: 5px;
}

.bx-flag-icon img {
    width: 26px;
    display: block;
    margin-top: -3px;
}

.box-circle, .box-circle-sm {
    display: block;
    width: 23px;
    height: 23px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
}

.box-arrow {
    border: solid 1px;
    display: inline-block;
    text-align: center;
    color: #025575;
    border-radius: 50%;
    line-height: 15px;
}

.box-circle-sm, .box-arrow {
    width: 18px;
    height: 18px;
}

.box-circle-small {
    width: 16px;
    height: 16px;
    display: block;
    float: left;
    margin-right: 5px;
}

.box-circle span {
    display: block;
    height: 100%;
    width: 50%;
    float: left;
}

.circle-orange {
    background-color: #f56237;
}

.circle-blue {
    background-color: #029390;
}

.circle-red {
    background-color: #ff103a;
}

.circle-yellow {
    background-color: #ffff00;
}

.circle-gray {
    background-color: #c1c1c1;
}

.circle-dark-purple {
    background-color: #dd2984;
}

.circle-green {
    background-color: #82c418;
}

.circle-pink {
    background-color: #ffc0cb;
}

.circle-black {
    background-color: #242c2e;
}

.box-ui-detail {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
}

.box-ui-detail ul {
    margin-bottom: 0px !important;
}

.box-ui-detail li {
    display: inline-block;
    margin-right: 10px;
    text-transform: uppercase;
    color: #000;
}

.text-dark-blue {
    color: #085069;
    margin-left: 30px;
}

.child {
    margin-right: 15px;
    position: relative;
}

.child-lg {
    width: 25%;
}

.child-md {
    width: 20%;
}

.child-sm {
    width: 15%;
}

.child-xs {
    width: 10%;
}

.child-cs {
    width: 12%;
}

.child p {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
    margin-left: 15px;
}

.dropdown-toggle::after {
    display: none !important;
}

.btn-custom, .input-cs {
    border: solid 1px #646464 !important;
    border-radius: 15px !important;
    color: #818181 !important;
    font-size: 13px !important;
    background-color: #ffff !important;
}

.text-black {
    color: #333;
}

.dropdown-cs {
    top: -3px !important;
    left: -1px !important;
    width: 101%;
    border-radius: 15px !important;
    font-size: 12px !important; 
    transform: inherit !important;
    max-height: 300px;
    overflow-y: auto;
}

.dropdown-cs a, .dropdown-custom a {
    padding: 2px 15px !important;
    color: #000 !important;
}

.dropdown-cs > a.dropdown-item {
    word-break: break-word !important;
    white-space: inherit !important;
}

.dropdown-cs > :focus, .dropdown-cs > :hover , .dropdown-custom > :focus, .dropdown-custom > :hover{
    border-radius: 5px !important;
}

.box-ui {
    display: flex;
    flex-direction: row;
    margin: 15px;
    padding-bottom: 15px;
    border-bottom: dashed 1px #db1175;
}

#datepicker {
    width: 180px;
    margin: 0 20px 0px 20px;
}

#datepicker > span:hover {
    cursor: pointer;
}

.box-tab-fixed, .box-tab-fixed-v2, .box-tab-fixed-v3 {
    position: fixed;
    height: 185px;
    z-index: 99;
    background-color: #e8e8e8;
}

.box-tab-fixed-v2 {
    height: 145px;
    width: 99%;
}

.box-tab-fixed-v3 {
    height: 75px;
}

.box-tab-fixed-v3 > .box-list-header > .header-tabs-menu {
    border-bottom: 0px !important;
}

.footer {
    background-color: #fff;
    padding: 15px 5px;
    color: #ababab;
    border-top: solid 1px #ddd;
}

.footer img {
    max-width: 35%;
}

.box-list {
    display: flex;
    flex-direction: row;
    padding: 15px 25px;
}

.box-list-header {
    padding: 0px 25px;
}

.box {
    margin-bottom: 5px;
    margin-top: 5px;
}

.box-list ul {
    margin: 0px;
}

.box li {
    display: inline-block;
    float: left;
}

.box li:first-child {
    padding-top: 5px;
}

.box strong {
    font-size: 13px;
    text-transform: uppercase;
}

.box-md {
    width: 15%;
}

.box-md td:first-child {
    width: 40px;
    padding-right: 10px;
}

.box-md {
    width: 15%;
}

.box-sm {
    width: 12%;
}

.box-sm td:first-child {
    width: 60px;
    padding-right: 10px;
}

.btn-dark-blue {
    color: #fff;
    background: rgba(13, 75, 112, 1);
    background: -moz-linear-gradient(top, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(13, 75, 112, 1)), color-stop(100%, rgba(22, 21, 65, 1)));
    background: -webkit-linear-gradient(top, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
    background: -o-linear-gradient(top, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
    background: -ms-linear-gradient(top, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
    background: linear-gradient(to bottom, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0d4b70', endColorstr='#161541', GradientType=0);
    border-radius: 15px !important;
    text-transform: uppercase;
    border: solid 1px #e40b7e;
    padding: 4px !important;
}

.row-table {
    margin-top: 145px;
}

.row-table-content {
    margin-top: 75px;
}

.box-table {
    background-color: #fff;
    padding: 45px 35px;
}

.box-wrapper {
    padding: 45px 65px;
}

.box-menu-left {
    color: #025575;
}

.box-menu-left li {
    border-top: dashed 1px #e40b7e;
    padding: 15px 0px;
    clear: both;
    list-style: none;
}

.box-menu-left strong {
    color: #025575;
}

.box-menu-left .active {
    color: #e40b7e;
}

.txt-big {
    font-size: 1.2em;
}

li.list-cs {
    padding-left: 35px;
}

.sub-box-menu li {
    padding: 12px 0px;
    text-transform: uppercase;
    font-size: 11px;
    color: #333;
    font-weight: bold;
    border: 0px;
    display: flex;
}

.sub-box-menu li .box-circle {
    margin: 0;
    margin-right:5px
}
.sub-box-menu li .legend-text{ width: 89%;}

.page-clinical-trial .sub-box-menu{
    position: absolute;
    top: 26px;
}
.page-clinical-trial .sub-box-menu li{  
    list-style: none;
    padding: 4px 0px;
} 
.page-clinical-trial .sub-box-menu .circle-green2{
    background-color: #009688;
}
.page-clinical-trial .sub-box-menu .circle-orange{
    background-color: #FFC107;
}
.page-clinical-trial .sub-box-menu .circle-gray{
    background-color: #9E9E9E;
}    
.page-clinical-trial .sub-box-menu .circle-very-high{
    background-color: #757070;
}  
.page-clinical-trial .sub-box-menu .circle-high{
    background-color: #989393;
}  
.page-clinical-trial .sub-box-menu .circle-medium{
    background-color: #cccccc;
}  
.page-clinical-trial .sub-box-menu .circle-low{
    background-color: #e6e6e6;
}
.sub-box-menu input{ float: left; margin-right: 5px;}  
.table-information {
    width: 100%;
    border-bottom: solid 1px #808080;
    border-top: solid 1px #808080;
}

.table-information th, .table-information td {
    border-right: solid 1px #808080;
    border-left: solid 1px #808080;
    text-align: center;
    padding: 3px 10px;
    font-size: 14px;
}

.table-information th.pink, .table-information td.pink {
    border-right: solid 2px pink !important;
    border-left: solid 2px pink !important; 
    border-top: solid 2px pink !important; 
    border-bottom: solid 2px pink !important; 
}

.table-information th:first-child, .table-information td:first-child {
    text-align: left;
}
.table-information tr.text-center th, .table-information tr.text-center td {
    text-align: center;
}
.table-information td:first-child {
    color: #000;
    font-weight: 500;
}

.table-information tr.header-tab_1 > th {
    background-color: #09415c;
    color: #fff;
}

.table-information tr.header-tab_2 > th {
    background-color: #115071;
    color: #fff;
    font-weight: normal;
}

.box-lg {
    padding: 22px 0px 22px 35px !important;
    font-size: 20px;
}

.box-large {
    padding: 50px 0px 50px 35px !important;
}

.box-content {
    display: flex;
    flex-direction: row;
}

.child-box-left {
    width: 300px;
    margin-right: 40px;
}

.child-box-right {
    width: 100%;
}

/*table fixed*/

.table-main {
    border: none;
    /*border-right: solid 1px #968c8b !important;*/
    border-collapse: collapse;
    border-spacing: 0;
}

.table-main thead th {
    white-space: nowrap;
}

.table-main tbody td {
    white-space: nowrap;
}

.table-z {
    position: relative;
    overflow-x: hidden;
}

.table-scroll {
    margin-left: 50px;
    overflow-x: scroll;
    overflow-y: visible;
    width: 96%;
}

.table-main .fix-col {
    border-right: solid 1px #968c8b !important;
    left: 0;
    position: absolute;
    top: auto;
    width: 50px;
}

/*document*/

.dropdown-custom:before, .dropdown-custom:after{
    content: '';
    display: inline-block;
    position: absolute;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: white;
    top: -7px;
    left: 12px;
}

.dropdown-custom{
    border-radius: 15px !important;
    font-size: 14px !important;
    left: -4px !important;
    border: solid 1px #ddd !important;
    box-shadow: 0px 2px #eee !important;
}

.bg-light-gray {
    background-color: #f2f2f2;
    padding: 20px 50px;
    clear: both;
}

.box-list-col {
    display: inline-flex;
    color: #025575;
    margin: 12px 15px;
}

.box-list-col > .col-chlid:first-child i {
    font-size: 5em;
    color: #e40b7e;
}

.col-tab-download {
    font-size: 11px;
}

.wrap-col-content {
    padding-left: 15px;
}

.col-content {
    margin: 5px 0px 10px 0px;
    font-weight: 600;
}

.col-content div:first-child {
    height: 25px;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
}
.col-content div:last-child{
    margin-top: -5px;
}


/*footer*/

.footer-info {
    text-align: right;
}

.tb-arrow {
    width: 100%;
    display: inline-block;
    background-image: url(/public/images/img-arrow.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 80px;
}


.wrapper-box-list {
    border-top: dashed 1px #db1175;
    position: relative;
}

.wrap-col, .box-list-alt {
    display: flex;
    flex-direction: row;
}
.box-col:last-child {
    width: 100%;
}
.box-list-alt{
    margin-bottom: 5px;
}

.box-list-alt > .list-child {
    width: 13.5%;
    padding: 0px 4px;
    position: relative;
}

.box-square-gray, .box-square-pink {
    display: block;
    background-color: #ececec;
    padding: 5px 20px 5px 5px;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    text-align: left;
    border: solid 1px transparent;
    word-break: break-word;
}

.box-square-gray span, .box-square-pink span {
    display: inline-block;
}

.box-square-gray i , .box-square-pink i {
    float: right;
    margin: 4px 5px 0px 20px;
    display: block;
    position: absolute;
    top: 5px;
    right: 8px;
}
 
  


.box-square-gray i{
    color: #db1175;
}
.box-square-gray .flag{
    margin-right: 5px;float: left;
}
.box-square-pink{
    background-color: #db1175;
    color: #fff;
}

.tb-title{
    font-weight: bold;
}

.wrapper-box-list:last-child{
    border-bottom: dashed 1px #f3b5d4;
}
.box-square-gray.top-year{
    text-align: center;
    background-color: #c5d6dd;
}
/*popover*/
.popover-content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: -150px;
    transform: translate(0, 10px);
    background-color: #fff;
    padding: 15px 11px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    width: 285px;
    z-index: 19;
    border: solid 1px #db1175;
    border-radius: 15px;
    top: 58px;
}
.popover-content:before {
    position: absolute;
    z-index: -1;
    content: "";
    left: calc(40% - 10px);
    top: -10px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    transition-duration: 0.3s;
    transition-property: transform;
    -webkit-filter: drop-shadow(0 -1px 0px #db1175);
}
.list-child:hover .popover-content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover-message {
    font-size: 12px;
}

.popover-message ul{
    margin: 0px;
    padding: 0px;
}
.popover-message ul>li{
    list-style: none;
}
.box-square-gray span, .box-square-pink span {
    display: inline-block;
}

.wrap-list-col {
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #ddd;
    padding: 15px 0px 10px 0px;
    width: 100%;
}

.wrap-list-col:last-child {
    border-bottom: none;
}

.list-col-head {
    color: #db1175;
    width: 70px;
    display: inline-block;
    padding-right: 15px;
    font-weight: bold;
    text-align: right;
}

.col-right {
    width: 100%;
}

.col-title {
    padding: 15px 15px 0px 0px;
    font-weight: bold;
    width: 150px;
    word-break: break-word;
}
.page-hta .box-menu-left{width: 277px;}
@media screen and (max-width: 1200px) {
    .box-md,.box-sm {
        width: 25%;
    } 
    .page-clinical-trial .box-sm,.page-clinical-trial .box-bt-right{
        width: 17%;
    }
}
@media screen and (max-width: 960px) {
    .header-logo img {
        max-width: 35% !important;
    }

    .header-sub-logo img {
        max-width: 65% !important;
        margin-left: -18px !important;
    }

    .header-right-logo {
        padding-right: 0px !important;
    }

    .header-right-logo img {
        max-width: 60% !important;

    }

    .header-logo-sm img {
        max-width: 100% !important;
        max-height: 57px !important;
    }

    .button-menu {
        width: 100% !important;
        font-size: 12px !important;
    }

    .box-menu {
        padding: 10px 10px !important;
    }

    .text-dark-blue, .button-circle {
        display: none !important;
    }

    .logo-right img {
        max-width: 65% !important;
        max-height: 57px !important;
    }

    .box-ui, .box-list, .box-content {
        flex-direction: column !important;
    }

    .child {
        width: 100% !important;
    }

    .box-tab-fixed {
        position: relative !important;
        height: inherit !important;
    }

    .wrapper-table {
        margin: inherit !important;
    }

    .box-ui-detail, .footer-info {
        text-align: left !important;
    }

    .box-ui-detail li, .button-menu {
        margin-bottom: 5px;
    }

    .box-md, .box-sm, .child-box-left {
        width: 100% !important;
    }

    .box-md td:first-child {
        width: 12% !important;
    }

    .row-table, .row-table-content {
        margin-top: 0px !important;
    } 

    .bg-light-gray {
        padding: 20px !important;
    }

}