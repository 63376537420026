body {
  padding-top: 58px;
}
.footer {
    border-top: solid 1px #ddd;
}
.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.circle-color {
  height: 27px;
  width: 27px;
  border-radius: 50%;
  margin: auto;
  position: relative;
  cursor: pointer;
}
.circle-color .tooltiptext {
  display: none;
}
/*.circle-color:hover .tooltiptext {
  border: 1px solid #a29696;
  padding: 7px;
  display: block;
  z-index: 100;
  background: #f0f0f0;  
  margin: 20px;
  width: 250px;
  position: absolute;  
  bottom: 0;
  text-decoration: none;
  right: -18px;
}
.circle-color .tooltiptext .line-hr {
  border-bottom: 1px solid #a29696;
  margin-top: 5px;
  margin-bottom: 5px;
}
*/

/* ICON FLAG */
.flag.size26 {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: inline-block;
  }
  .flag.size20 {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
  }
.flag.size17 {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
}
.flag.size17_1 {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
}
.flag.size35 {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-block;
}

.flag {
  position: relative;
  text-transform: capitalize;
}
.flag.world {
  background: url(/public/images/icon-flag/flag-world.png);
  background-size: cover;
}
.flag.ema,.flag.inter {
  background: url(/public/images/icon-flag/flag-ema.svg);
  background-size: cover;
}
.flag.fda{
  background: url(/public/images/icon-flag/flag-fda.svg);
  background-size: cover;
}
.flag.fr{
  background: url(/public/images/icon-flag/flag-france.svg);
  background-size: cover;
}
.flag.g{
  background: url(/public/images/icon-flag/flag-germany.svg);
  background-size: cover;
}
.flag.it{
  background: url(/public/images/icon-flag/flag-italy.svg);
  background-size: cover;
}
.flag.uk{
  background: url(/public/images/icon-flag/flag-uk.svg);
  background-size: cover;
}
.flag.es{
  background: url(/public/images/icon-flag/flag-spain.svg);
  background-size: cover;
}
.flag.sw{
  background: url(/public/images/icon-flag/flag-sweden.svg);
  background-size: cover;
}
.flag.sco{
  background: url(/public/images/icon-flag/flag-scotland.svg);
  background-size: cover;
}
.flag.pl{
  background: url(/public/images/icon-flag/flag-poland.svg);
  background-size: cover;
}
.flag.nd,.flag.nl{
  background: url(/public/images/icon-flag/flag-netherlands.svg);
  background-size: cover;
}
.flag.ie{
  background: url(/public/images/icon-flag/flag-ireland.svg);
  background-size: cover;
}
.flag.be{
  background: url(/public/images/icon-flag/flag-belgium.svg);
  background-size: cover;
}
.flag.wl{
  background: url(/public/images/icon-flag/flag-wales.svg);
  background-size: cover;
}
.flag.us {
  background: url(/public/images/icon-flag/flag-usa.svg);
  background-size: cover;
}
.flag.ca{
  background: url(/public/images/icon-flag/flag-canada.svg);
  background-size: cover;
}
.flag.co,.flag.col{
  background: url(/public/images/icon-flag/flag-colombia.svg);
  background-size: cover;
}
.flag.br{
  background: url(/public/images/icon-flag/flag-brazil.svg);
  background-size: cover;
}
.flag.pe{
  background: url(/public/images/icon-flag/flag-peru.svg);
  background-size: cover;
}
.flag.au{
  background: url(/public/images/icon-flag/flag-australia.svg);
  background-size: cover;
}
.flag.zn{
  background: url(/public/images/icon-flag/flag-newzealand.svg);
  background-size: cover;
}
.flag.jp{
  background: url(/public/images/icon-flag/flag-japan.svg);
  background-size: cover;
}
.flag.sg{
  background: url(/public/images/icon-flag/flag-singapore.svg);
  background-size: cover;
}
.flag.england{
  background: url(/public/images/icon-flag/flag-england.svg);
  background-size: cover;
}
.flag.kr{
  background: url(/public/images/icon-flag/flag-korea.svg);
  background-size: cover;
}
.flag.tw{
  background: url(/public/images/icon-flag/flag-taiwan.svg);
  background-size: cover;
}
.table th{
    font-size: 14px;
}
.table td{
    font-size: 14px;
    font-weight: normal;
}
.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}
.box-flag {
    margin-top: 2px;
}
.row-table{ border: none;}
.box-lg {
    font-size: 19px;
}
.sub-box-menu ul{padding: 0; margin: 0;}

.modal-content .line-hr {
  border-bottom: 1px solid #a29696;
  margin-top: 5px;
  margin-bottom: 5px;
}
.main-overlay{position: relative;}
.main-overlay .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(226, 221, 221, 0.51);
  border-radius: inherit;
}
.main-overlay .overlay>.fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}

.custom-datepicker.react-datepicker{
  border-radius: 18px;
}
.custom-datepicker .react-datepicker__header{
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding-top: 4px;
}
.custom-datepicker .react-datepicker__month .react-datepicker__month-text, .custom-datepicker .react-datepicker__month .react-datepicker__quarter-text{
  width: 3rem;
}
.custom-datepicker .react-datepicker__navigation{
  top: 6px;
}
.border-none{border: none !important;}
.box-tab-fixed{height: auto;}
.header-tabs-menu {
  border-bottom: 0px;
}
.page-hta .header-tabs-menu, .page-clinical-trial .header-tabs-menu{border-bottom: dashed 1px #db1175;}
.box-round{color: #fff;margin-right: 3px;}
.box-arrow{margin-right: 3px;}
.box-menu-left .list_a{color: #025575;}
.box-menu-left .list_a.active{color: #e40b7e;}
.btn-circle {
  width: 29px;
  height: 29px;
  font-size: 14px;
  padding: 4px 0;
  margin-right: 5px;
}
.a-box-arrow{color: #025575 !important;}

.green{color:#4CAF50;}
.red{color:red;}
.yellow{color:#e8e86c;}
.col-tab-download{cursor: pointer;}

.modal-icon-pdf {
  width: 25px;
  height: 25px;
  background: url(/public/images/icon-pdf.png) no-repeat;
  background-size: 100%;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  margin-left: 5px;
  ;
}

.menu-float-right{
  float: right;
  padding: 5px 15px;
}
.main-box-wrapper-clinical{clear: both;}
.box-wrapper-clinical{ position: relative; }



/*page methodology [start]*/

.wrapper-content {
  padding: 15px 65px;
}

.box-bt-right {
  margin-left: auto;
  order: 2;
}

.wrap-cols {
  padding: 0px !important;
}

.col-border {
  padding: 10px 0px 5px 5px;
  border-left: dashed 1px #db1175;
}
.list-col-head, .col-title {
  padding-top: 10px !important;
}
.button-primary {
  display: inline-block;
  border: solid 1px #2c538c;
  padding: 5px 25px;
  color: #2c538c;
  text-align: center;
  cursor: pointer;
  word-break: break-word;
  text-transform: uppercase;
  border-radius: 5px;
  background-color: #fff;
  font-size: 15px;
}
.btn-methodology{
  padding-left: 30px !important;
  padding-right: 30px !important;
  background: #fff;
  color: #2c538c;
  border: 1px solid #2c538c;
  cursor: pointer;
}
.btn-methodology.active-bt{
  background: rgba(13, 75, 112, 1);
  background: -moz-linear-gradient(top, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(13, 75, 112, 1)), color-stop(100%, rgba(22, 21, 65, 1)));
  background: -webkit-linear-gradient(top, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
  background: -o-linear-gradient(top, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
  background: -ms-linear-gradient(top, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
  background: linear-gradient(to bottom, rgba(13, 75, 112, 1) 0%, rgba(22, 21, 65, 1) 100%);
  
  color: #fff;
}

.box-square {
  display: block;
  background-color: #c5d6dd;
  padding: 1px;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  border: solid 1px transparent;
  word-break: break-word;
  text-align: center;
  color: #202a2c;
}

.bg-dark-blue, .bg-blue, .bg-light-blue {
  /*border: solid 2px #c5d6dd;*/
  color: #fff;
}

.bg-dark-blue {
  background-color: #1f3763;
}

.bg-blue {
  background-color: #2e5495;
}

.bg-light-blue {
  background-color: #8fabdd;
  border: solid 2px #2e5495;
}

.box-list-child > .list-child {
  width: 16.6%;
}

.box-contents {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.bx-box-left {
  width: 200px;
  border-right: dashed 1px #db1175;
  padding: 37px 0px 0px 0px;
  margin-right: 30px;
  font-size: 14px;
}

.bx-box-right {
  width: 100%;
}

.bx-circle {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  float: left;
  margin: 0px 7px 0px 0px;
}

.bg-very-high {
  background-color: #757070;
}

.border-very-high {
  border: solid 2px #757070;
}

.bg-high {
  background-color: #989393;
}

.border-high {
  border: solid 2px #989393;
}

.bg-medium {
  background-color: #cccccc;
}

.border-medium {
  border: solid 2px #cccccc;
}

.bg-low {
  background-color: #e6e6e6;
}

.border-low {
  border: solid 2px #e6e6e6;
}

table.table-menu td {
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px 0px;
  vertical-align: middle;
}

table.table-menu {
  width: 100%;
}

.wrapper-box-list:last-child {
  border-bottom: 0px !important;
}

/* .squaredThree */
.squaredThree {
  position: relative;
  float: left;
  margin: 3px 10px 0px 0px;
}

.squaredThree label {
  width: 17px;
  height: 17px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background: #3b87fe;
  border-radius: 4px;
}

.squaredThree label:after {
  content: '';
  width: 11px;
  height: 5px;
  position: absolute;
  top: 5px;
  left: 3px;
  border: 2px solid #fcfff4;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.squaredThree label:hover::after {
  opacity: 0.3;
}

.squaredThree input[type=checkbox] {
  visibility: hidden;
}

.squaredThree input[type=checkbox]:checked + label:after {
  opacity: 1;
}

/* end .squaredThree */

.label-text {
  position: relative;
  left: 10px;
}

.nav-pills-custom li {
  width: 25%;
}

.nav-pills-custom li > a {
  background-color: #de8ca2 !important;
  margin: 5px;
  border-radius: 0px !important;
  border: solid 2px #bf1a45 !important;
  color: #fff !important;
  font-size: 18px !important;
  padding: 5px 10px !important;
}

.nav-pills-custom li > a.active {
  background-color: #bf1a45 !important;
}

.box-content-info {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  align-items: stretch;
}

.bx-first-chlid {
  width: 240px;
  background-color: #09506e;
  margin-right: 8px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  padding: 25px 10px;
  line-height: 22px;
}

.bx-first-chlid > .box-text-header {
  display: table;
  width: 100%;
  height: 100%;
}

.bx-first-chlid span {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}
.bx-last-child {
  background-color: #e5edf0;
  width: 100%;
  border: solid 2px #09506e;
  color: #09506e;
  padding: 10px 15px;
  line-height: 20px;
}

.bx-first-chlid > span {
  display: block;
}

.bx-title-header {
  font-weight: bold;
  text-decoration: underline;
}
.bx-details {
  height: 100%;
  display: table;
  width: 100%;
}
.bx-details ul {
  margin-left: 20px;
  margin-bottom: 0px;  
  display: table-cell;
  vertical-align: middle;
  border-collapse: collapse;
  height: 100%;
  padding: 0;
}

.bx-details li {
  list-style: disc;
  margin-left: 20px;
}
.bx-details li.title-nolist {
  list-style: none;
  margin-left: 0px !important;
}
.box-arrow-down {
  width: 200px;
  margin-bottom: 5px;
  text-align: center;
}

.box-arrow-down img {
  width: 20px;
}

.header-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.header-title span {
  text-decoration: underline;
  color: #09506e;
  font-size: 20px;
}

.box-wrap {
  padding: 15px;
  border: solid 2px #8d847b;
  background-color: #f3f3f1;
  margin-right: 5px;
  margin-left: 5px;
}

.bg-pink {
  background-color: #da1177;
}

.text-green {
  color: #16b561;
}

.text-orange {
  color: #ff6424;
}

.text-red {
  color: #ff0e1b;
}

.text-yellow {
  color: #ffbd2e;
}
a.nav-link{cursor: pointer;;}

.alert-modal .modal-footer{border-top: none; padding-top: 0;}
.alert-modal .modal-body{text-align: center;padding: 1.5rem 1rem;}

.text-center-bold{ text-align: center !important; font-weight: 500 !important;}
.tr-gray{background-color: #f2f2f2;}
.html2canvasreset{
  overflow: visible !important;
  width: auto !important;
  height: auto !important;
  max-height: auto !important;
}
.clinical-control{
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
   
}
.item-control{
  padding-left: 5px;
}

.main-table td.td-table{padding: 0;}

.sub-table{ margin-bottom: 0;}
.main-table td.td-table .first-log{margin: .75rem;}
.hidden{display: none}
@media screen and (max-width: 1362px) {
  .menu-float-right{
    float: none;
  }
  .box-dropdown {
    position: relative;
    padding: 0 6px 6px 6px;
  }
  .box-md-date td:nth-child(2){width:43% ;}
  .box-md-date td:nth-child(4){width:44% ;}
  .react-datepicker-wrapper{width: 100%;}
  .main-box-wrapper-clinical{overflow-x: auto; overflow-y: hidden;}
  .box-wrapper-clinical{ width: 1200px;}
  .page-clinical-trial .box-wrapper{padding: 45px 12px;}
  .box-content-info {
      flex-direction: column !important;
  }

  .bx-first-chlid, .nav-pills-custom li {
      width: 100% !important;
  }
}
.previous_decision{
  font-size: 14px;
  padding-left: 16px;
  margin: 0 20px 9px 20px;
}
.previous_decision + .previous_decision{
  border-top: 1px solid #a29696;
}
.num_decision{
  position: absolute;
  bottom: -3px;
  right: -8px;
  font-size: 12px;
}